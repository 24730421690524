<template>
	<div>
		<div class="container darkBack">
			<div class="whiteFont font20">企业数量占比</div>
			<div class="num">绍兴市体育企业总数：<span>{{num}}</span></div>
			<div id="industryType"></div>
		</div>
	</div>
</template>

<script>
	import bus from "../../../assets/js/bus"
	export default {
		data() {
			return{
				num:0,
				pieDatas:[
					// {
					// 	"value": 14,
					// 	"name": "体育服饰"
					// },{
					// 	"value": 26,
					// 	"name": "健身训练"
					// },{
					// 	"value": 20,
					// 	"name": "体育活动赛事"
					// },{
					// 	"value": 10,
					// 	"name": "体育健康医疗"
					// },{
					// 	"value": 10,
					// 	"name": "啊啊啊"
					// }
				],
			}
		},
		methods:{
			// 绘制体育行业类别表
			drawIndustryType(number){
				// let myChart = this.$echarts.init(document.getElementById("industryType"));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("industryType"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("industryType"));
				}
				let series = [];
				let pieDatas = number;
				let maxRadius =  60,
				    barWidth = 5,
				    barGap =  5;
				let sumValue = 0;
				let showValue = true,showPercent = true;
				pieDatas.map(item => {
				    sumValue += item.value;
				})
				let barColor =  [
				        {"color1": "#0E6DE9",},
				        {"color1": "#19AF6D",},
				        {"color1": "#E6AF08",},
				        {"color1": "#BD54EB",},
				        {"color1": "#E35D68",},
				        {"color1": "#6AD4F9",},
				    ];
				pieDatas.map((item, i) => {
				    series.push({
				        type: 'pie',
						clockWise: false, //逆时针加载
				        hoverAnimation: false, //鼠标移入变大
				        radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
				        center: [ "30%", "50%"],
				        label: {show: false},
				        itemStyle: {
				            label: {show: false,},
				            labelLine: {show: false},
				            borderWidth: 5,
				        },
				        data: [{
				            value: item.value,
				            name: item.name,
				            itemStyle: {
				                color: barColor[i].color1
				            }
				        }, {
				            value: sumValue - item.value,
				            name: '',
				            itemStyle: {
				                color: "transparent",
				            },
				            hoverAnimation: false
				        }]
				    })
				    series.push({
				        name: 'blank',
				        type: 'pie',
						clockWise: false, //逆时针加载
				        silent: true,
				        z: 0,
				        hoverAnimation: false, //鼠标移入变大
				        radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
				        center: [ "30%", "50%"],
				        label: {
				            show: false
				        },
				        itemStyle: {
				            label: {show: false,},
				            labelLine: {show: false},
				            borderWidth: 5,
				        },
				        data: [{
				            value: 1,
				            itemStyle: {
				                color: "#0A0F5F",
				                borderWidth: 0
				            },
				            hoverAnimation: false
				        }]
				    });
				})
				let option = {
					tooltip: { // 鼠标hover 弹窗
						trigger: 'item',
						formatter: "{b}: {c} ({d}%)", //"{a} <br/>{b}: {c} ({d}%)"
						backgroundColor:"rgba(0,0,0,0.7)", 
						textStyle:{
							color:"white"
						},
					},
				    legend: {
				        show: true,
				        left: '60%',
				        top: 'middle',
				        itemWidth: 10,
				        itemHeight: 10,
				        itemGap:  5,
				        textStyle: {
				            fontSize:  '0.12rem',
				            color:  '#A8DAEB',
				        },
				        formatter: (name) => {
							var datas = pieDatas;
							let total = 0;
							datas.map(item => {
								total += (item.value - 0)
							})
							let valueIndex = datas.map(item => item.name).indexOf(name);
							return name + "  " + (showPercent ? ((datas[valueIndex].value / total) * 100).toFixed(2) + "%" : '');
						} ,
				    },
				    series: series,
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			}
		},
		mounted() {
			let that = this;
			that.drawIndustryType(that.pieDatas);
			bus.$on('enterpriseType',function(res){
				// console.log(res)
				that.pieDatas=[];
				that.num = 0;
				res.forEach(item=>{
					that.pieDatas.push({
						"value": item.count,
						"name": item.name
					})
					that.num += item.count;
				})
				// console.log(that.pieDatas)
				that.drawIndustryType(that.pieDatas);
			})
		},
		destroyed () {
			bus.$off('enterpriseType');
		}
	}
</script>

<style scoped>
	.container{
		width: 4.5rem;
		height: 3.1rem;
		padding: 0.2rem;
		position: relative;
	}
	#industryType{
		width: 4.40rem;
		height: 2.80rem;
		margin: -0.3rem 0rem 0rem -0.2rem;
	}
	.num{
		font-size: 0.2rem;
		color: #A8DAEB ;
		margin-top: 0.2rem;
	}
	.num span{
		color: #1fd0f7;
	}
</style>